import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { Scene } from 'react-scrollmagic';
import parse from 'html-react-parser';
import moment from 'moment';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import configs
import { apiBasePath } from './../../other/configs/urls';

// Import components
import ProgressiveImg from './../../components/progressive-img/progressive-img';

// Import styles
import './post-item.scss';

interface props {
	'postId': string,
	'userId': string,
	'profileId': string,
	'isProfileVisible': boolean,
	'profileStatus': number,
	'profileSlug': string,
	'images': Array<any>,
	'name': string,
	'profileNetworks'?: Array<any>,
	'text': {[key: string]: any},
	'country'?: string,
	'city'?: string,
	'type'?: string,
	'category'?: string,
	'interest'?: string,
	'likes'?: number,
	'dislikes'?: number,
	'isLiked'?: boolean,
	'isDisliked'?: boolean,
	'comments': number,
	'createdAt': string,
	'status'?: number,
	'statusMessage'?: string,
	'kind'?: string,
	'slug'?: string,
	'isInSlider'?: boolean,
	'clickedUrlCheck': any,
	'postToReportId': any,
	'postToRemoveId': any,
	'onViewStatusMessage'?: any
	'onToggleLikeDislike'?: any
};

const PostItem = (props: props) => {
	const location = useLocation();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const [isTextExpandable, setIsTextExpandable] = useState<boolean>(false);
	const [isTextExpanded, setIsTextExpanded] = useState<boolean>(false);

	const postRef = useRef<any>(null);

	useEffect(() => {
		if (postRef.current?.clientHeight > 100) {
			setIsTextExpandable(true);
		}

		if (
			props.text?.text.includes('instagram') ||
			props.text?.text.includes('tiktok')
		) {
			setIsTextExpanded(true);
		}

		if (!props.slug) {
			setIsTextExpanded(true);
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const adaptText = (text: any) => {
		// Define your URL pattern
		const urlPattern = /(https?:\/\/[^\s]+)/g;

		// If this is not a post page, strip tags
		if (location.pathname.split('/')[1] !== slugByPageId('post' , lang, routes).split('/')[1]) {
			text = text.replace(/(<([^>]+)>)/gi, '').replace(/[\r\n]{3,}/g, '\n\n');
		}

		// Check if any URL contains Instagram post or TikTok
		let mediaType: string = '';

		if (text.includes('instagram') || text.includes('tiktok')) {
			if (text.includes('/reel/')) {
				mediaType = 'ig-reel';
			}

			if (text.includes('/p/')) {
				mediaType = 'ig-post';
			}

			if (text.includes('tiktok')) {
				mediaType = 'tiktok';
			}
		}

		// Replace URLs based on the presence of Instagram or TikTok
		text = text.replace(urlPattern, function(url: any) {
			if (url.includes('instagram') || url.includes('tiktok')) {
				// For Instagram and TikTok, return the embed code
				return '<div class="TEXT__embed ' + mediaType + '">' +
					   '<iframe src="' + url + '" title="' + (props.slug || props.postId) + '" scrolling="no"></iframe>' +
					   '</div>';
			} else {
				// For other URLs, return a clickable link
				if (
					(auth.unlockedContentEndsAt && moment(auth.unlockedContentEndsAt).isAfter(moment())) ||
					(auth.id === props.userId)
				) {
					return '<a href="' + url + '" target="_blank" class="LINK">' + url + '</a>';
				}

				return url;
			}
		});
		
		// Allow html tags
		return '<p>' + text + '</p>';
	}

	let profileImage: string = '';

	if (props.images?.[0]?.path) {
		profileImage = apiBasePath + '/uploads/' + props.images[0].path;
	}

	return (
		<div
			className={'POSTITEM' + ((
				auth.isAuthenticated && 
				props.userId === auth.id &&
				props.onViewStatusMessage &&
				props.status === 0
			) ? ' diff1' : '')}
			data-usid={auth.adminType >= 1 ? props.userId : ''}
			data-prid={auth.adminType >= 1 ? props.profileId : ''}
			data-poid={auth.adminType >= 1 ? props.postId : ''}
		>
			<div className="POSTITEM__content">
				{
					props.slug &&
					<Link to={slugByPageId('post', lang, routes).replace(':id/:slug', props.postId + '/' + props.slug)} className="PROFILEITEM__link"></Link>
				}

				<div className="grid">
					<div className="row">
						<div className="col-12">
							<div className="POSTITEM__section">
								<div className="POSTITEM__header">
									{
										(props.isProfileVisible && props.profileStatus === 1) &&
										<div className={'PROFILEIMAGE' + (!props.profileSlug ? ' diff1' : '')}>
											{
												props.profileSlug &&
												<Link to={slugByPageId('profile', lang, routes).replace(':slug', props.profileSlug)} className="LINK"></Link>
											}

											<span className="PROFILEIMAGE__inner">
												<span className={generalText.profileItem?.hiddenProfile?.icon}></span>
												<Scene>
													{
														(progress: any, event: any) => (
															<div className="image">
																<ProgressiveImg
																	img={false}
																	noAnimation={true}
																	showLoader={true}
																	shouldLoad={true}
																	lowQualitySrc=""
																	highQualitySrc={profileImage}
																/>
															</div>
														)
													}
												</Scene>
											</span>
										</div>
									}

									{
										(!props.isProfileVisible || props.profileStatus !== 1) && 
										generalText.profileItem?.hiddenProfile?.icon &&
										<div className="PROFILEIMAGE diff1">
											<span className="PROFILEIMAGE__inner">
												<span className={generalText.profileItem?.hiddenProfile?.icon}></span>
											</span>
										</div>
									}

									<div className="POSTITEM__header-info">
										{
											(
												props.isProfileVisible && 
												props.profileStatus === 1 &&
												props.name
											) &&
											<Link to={slugByPageId('profile', lang, routes).replace(':slug', props.profileSlug)} className="PROFILEITEM__name LINK">{ props.name }</Link>
										}

										{
											(!props.isProfileVisible || props.profileStatus) !== 1 && 
											generalText.profileItem?.hiddenProfile?.title &&
											<div className="PROFILEITEM__name">{ generalText.profileItem?.hiddenProfile?.title }</div>
										}
										
										<div className="PROFILEITEM__infobar">
											{
												auth.isAuthenticated && 
												props.createdAt &&
												moment().diff(moment(props.createdAt), 'days') < 90 &&
												<span>{ moment(props.createdAt).fromNow().charAt(0).toUpperCase() + moment(props.createdAt).fromNow().slice(1) }</span>
											}

											{
												props.country &&
												generalText.postItem?.country?.text &&
												<span>
													{
														parse(generalText.postItem?.country?.text, {
															replace: (domNode: any) => {
																if (domNode.attribs?.class === '%repStr1%') {
																	return <strong>
																		<span className={generalText.postItem?.country?.icon}></span> { props.country || generalText.postItem?.anyText }
																	</strong>;
																}
															}
														})
													}
												</span>
											}

											{
												props.city &&
												generalText.postItem?.cityText &&
												<span>
													{
														parse(generalText.postItem?.cityText, {
															replace: (domNode: any) => {
																if (domNode.attribs?.class === '%repStr1%') {
																	return <strong>
																		{ props.city || generalText.postItem?.anyText }
																	</strong>;
																}
															}
														})
													}
												</span>
											}

											{
												props.type &&
												generalText.postItem?.type?.text &&
												<span>
													{
														parse(generalText.postItem?.type?.text, {
															replace: (domNode: any) => {
																if (domNode.attribs?.class === '%repStr1%') {
																	return <strong>
																		<span className={generalText.postItem?.type?.icon}></span> { props.type || generalText.postItem?.anyText }
																	</strong>;
																}
															}
														})
													}
												</span>
											}

											{
												props.category &&
												generalText.postItem?.category?.text &&
												<span>
													{
														parse(generalText.postItem?.category?.text, {
															replace: (domNode: any) => {
																if (domNode.attribs?.class === '%repStr1%') {
																	return <strong>
																		<span className={generalText.postItem?.category?.icon}></span> { props.category || generalText.postItem?.anyText }
																	</strong>;
																}
															}
														})
													}
												</span>
											}

											{
												generalText.postItem?.interest?.text &&
												props.interest &&
												<span>
													{
														parse(generalText.postItem?.interest?.text, {
															replace: (domNode: any) => {
																if (domNode.attribs?.class === '%repStr1%') {
																	return <strong>
																		<span className={generalText.postItem?.interest?.icon}></span> { props.interest || generalText.postItem?.anyText }
																	</strong>;
																}
															}
														})
													}
												</span>
											}

											{
												(
													auth.isAuthenticated && 
													props.userId === auth.id
												) && 
												generalText.removeLink?.text &&
												<span onClick={() => props.postToRemoveId(props.postId)} className="LINK diff2">
													{ generalText.removeLink?.text }
												</span>
											}

											{
												(
													auth.isAuthenticated && 
													props.userId !== auth.id
												) && 
												generalText.reportLink?.text &&
												<div onClick={() => props.postToReportId(props.postId)} className="LINK diff2">
													{ generalText.reportLink?.text }
												</div>
											}
										</div>

										<div className="PROFILEITEM__infobar">
											{
												(
													auth.isAuthenticated && 
													props.userId === auth.id &&
													props.onViewStatusMessage &&
													typeof props.status !== 'undefined'
												) &&
												<div className="PROFILEITEM__status-textbubbles">
													{
														props.status === 0 && 
														generalText.postItem?.status?.text1 &&
														<span className="TEXTBUBBLE has-bg font-xxxxs no-top-margin bg-yellow">{ generalText.postItem?.status?.text1 }</span>
													}

													{
														props.status === 1 && 
														generalText.postItem?.status?.text2 &&
														<span className="TEXTBUBBLE has-bg font-xxxxs no-top-margin bg-green">{ generalText.postItem?.status?.text2 }</span>
													}

													{
														props.status === 2 && 
														generalText.postItem?.status?.text3 &&
														<span className="TEXTBUBBLE has-bg font-xxxxs no-top-margin bg-red clickable" onClick={() => props.onViewStatusMessage(props.statusMessage)}>
															{ generalText.postItem?.status?.text3 }
														</span>
													}
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>

						{
							props.text?.text &&
							<div className="col-12 POSTITEM__body">
								<div className="POSTITEM__section">
									<div className={'PROFILEITEM__text margin-top-sm' + 
										(props.slug ? ' no-pointer-events' : '')
									}>
										<div className={'text' +
											(isTextExpandable && !isTextExpanded ? ' expandable' : '') +
											(isTextExpanded ? ' expanded' : '')}
										>
											<div dangerouslySetInnerHTML={{__html: adaptText(props.text.text)}} ref={postRef}></div>
										</div>

										{
											(
												isTextExpandable && 
												!isTextExpanded
											) && 
											generalText.postItem?.readMoreText &&
											<div className="PROFILEITEM__text-readmore">
												{/*<span className="LINK" onClick={() => setIsTextExpanded(!isTextExpanded)}>{ generalText.postItem?.readMoreText }</span>*/}
												
												<Link className="LINK" to={slugByPageId('post', lang, routes).replace(':id/:slug', props.postId + '/' + props.slug)}>
													{ generalText.postItem?.readMoreText }
												</Link>
											</div>
										}

										{
											auth.isAuthenticated &&
											auth.id !== props.userId &&
											props.kind === 'Opportunities' &&
											(
												!auth.unlockedContentEndsAt ||
												moment(auth.unlockedContentEndsAt).isBefore(moment())
											) &&
											<Link to={slugByPageId('settings', lang, routes) + '' + generalText.postItem?.unlockPosts?.queryParams} className="LINK font-xxxs">
												{ generalText.postItem?.unlockPosts?.text }
											</Link>
										}
									</div>
								</div>
							</div>
						}

						{
							props.isProfileVisible &&
							props.profileStatus === 1 &&
							props.profileNetworks &&
							<div className="col-12 POSTITEM__body">
								<div className="POSTITEM__section">
									<div className="margin-top-sm">
										<div className="PROFILEITEM__networks">
											{
												_.map(props.profileNetworks, (val1, i1) => {
													if (i1 > 2) { return; }

													return(
														<div key={i1} onClick={() => props.clickedUrlCheck({'slug': props.profileSlug, 'url': val1.url})} className={'PROFILEITEM__networks-item bg-' + val1.network?.toLowerCase()}>
															{
																(val1.network?.toLowerCase() === 'website') &&
																<span className="icon-globe-regular"></span>
															}

															{
																(val1.network?.toLowerCase() !== 'website') &&
																<span className={'icon-' + val1.network?.toLowerCase() }></span>
															}
														</div>
													)
												})
											}
										</div>
									</div>
								</div>
							</div>
						}

						{
							(
								props.kind?.toLowerCase() === 'educational' ||
								props.kind?.toLowerCase() === 'opportunity' ||
								props.kind?.toLowerCase() === 'feedback' ||
								props.kind?.toLowerCase() === 'question'
							) &&
							<div className="col-12 POSTITEM__body">
								<div className="POSTITEM__section">
									<div className="POSTITEM__options margin-top-xs">
										{
											(
												props.kind?.toLowerCase() === 'opportunity' ||
												props.kind?.toLowerCase() === 'educational'
											) &&
											<span onClick={() => props.onToggleLikeDislike(props.postId, true)} className={'POSTITEM__options-item' + (!_.isEmpty(props.isLiked) ? ' active' : '')}>
												<span className="icon-heart-solid"></span>
												<span>{ props.likes || 0 }{/* generalText.postItem?.likes?.likeText */}</span>
											</span>
										}

										{
											(
												props.kind?.toLowerCase() === 'opportunity' ||
												props.kind?.toLowerCase() === 'educational'
											) &&
											<span onClick={() => props.onToggleLikeDislike(props.postId, false)} className={'POSTITEM__options-item dislike' + (!_.isEmpty(props.isDisliked) ? ' active' : '')}>
												<span className="icon-heart-solid"></span>
												<span>{ props.dislikes || 0 }{/*  generalText.postItem?.likes?.dislikeText */}</span>
											</span>
										}

										{
											(
												props.kind?.toLowerCase() === 'feedback' ||
												props.kind?.toLowerCase() === 'question'
											) &&
											<Link to={slugByPageId('post', lang, routes).replace(':id/:slug', props.postId + '/' + props.slug)} className={'POSTITEM__options-item' + (!props.slug ? ' no-pointer-events' : '')}>
												<span className={generalText.postItem?.comments?.icon}></span>
												<span>{ props.comments || 0 }{/*  generalText.postItem?.comments?.text */}</span>
											</Link>
										}
									</div>
								</div>
							</div>
						}

						{
							(
								props.kind || 
								generalText.postItem?.comments?.kindText
							) &&
							<div className="col-12">
								<div className="POSTITEM__section">
									<div className="PROFILEITEM__footer margin-top-xs">
										<div className="PROFILEITEM__footer-textbubbles full-width">
											<span className={'TEXTBUBBLE has-bg ' + props.kind?.toLowerCase()}>{ props.kind || generalText.postItem?.comments?.kindText }</span>
										</div>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default PostItem;
